import { useMemo } from 'react';

import {
	Footer,
	FooterBody,
	FooterFooter,
	FooterItem,
	FooterLink,
	FooterList,
	FooterText,
	FooterTitle,
} from '@playbooks/interface/footers';
import { Col, Container, Grid } from '@playbooks/interface/grid';
import { IFrame } from '@playbooks/interface/html';
import { NavbarBrand } from '@playbooks/interface/navbars';
import { AppThemeToggle } from 'components/app/app-theme-toggle';
import { useTheme } from 'contexts';

const AppFooter = () => {
	const theme = useTheme();
	const BrandIcon = '/branding/playbooks-logo.svg';
	const BrandIconDark = '/branding/playbooks-logo-dark.svg';

	// Computed
	const betterStackUrl = useMemo(() => {
		if (theme.isDark) return `https://playbooks.betteruptime.com/badge?theme=dark`;
		return `https://playbooks.betteruptime.com/badge?theme=light`;
	}, [theme.isDark]);

	// Render
	return (
		<Footer>
			<FooterBody>
				<Container>
					<Grid gap='gap-8'>
						<Col lg='4' space='space-y-4'>
							<NavbarBrand src={theme.isDark ? BrandIconDark : BrandIcon} alt='Brand Logo' />
							<FooterText width='max-w-[380px]'>
								Jumpstart your next project with a premium, hand-crafted software template built by your peers. Join the
								hybrid source software movement.
							</FooterText>
							<FooterList display='flex-start' space='space-x-2' spacing='-ml-2'>
								<FooterItem>
									<FooterLink
										size='icon'
										alt='twitter x'
										icon={{ type: 'fab', icon: 'x-twitter' }}
										fontSize='text-lg'
										target='_blank'
										href='https://x.com/playbooksxyz'
									/>
								</FooterItem>
								<FooterItem>
									<FooterLink
										size='icon'
										alt='github'
										icon={{ type: 'fab', icon: 'github' }}
										fontSize='text-lg'
										target='_blank'
										href='https://github.com/playbooks-community'
									/>
								</FooterItem>
								<FooterItem>
									<FooterLink
										size='icon'
										alt='youtube'
										icon={{ type: 'fab', icon: 'youtube' }}
										fontSize='text-lg'
										target='_blank'
										href='https://youtube.com/@playbooks-xyz'
									/>
								</FooterItem>
								<FooterItem>
									<FooterLink
										size='icon'
										alt='slack'
										icon={{ type: 'fab', icon: 'slack' }}
										fontSize='text-lg'
										target='_blank'
										href='https://slack.playbooks.xyz'
									/>
								</FooterItem>
							</FooterList>
						</Col>
						<Col lg='8' className='lg:flex-end'>
							<Grid>
								<Col span='6' lg='3' className='pb-4'>
									<FooterTitle spacing='mb-4'>Company</FooterTitle>
									<FooterList>
										<FooterItem>
											<FooterLink size='' href={`/about`}>
												About
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/pricing`}>
												Pricing
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={process.env.NEXT_PUBLIC_BLOG_DOMAIN}>
												Blog
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={process.env.NEXT_PUBLIC_DOCS_DOMAIN}>
												Docs
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={process.env.NEXT_PUBLIC_API_DOMAIN + '/reference'} target='_blank'>
												API Reference
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/community`}>
												Community
											</FooterLink>
										</FooterItem>
									</FooterList>
								</Col>
								<Col span='6' lg='3' className='pb-4'>
									<FooterTitle spacing='mb-4'>Browse</FooterTitle>
									<FooterList>
										<FooterItem>
											<FooterLink size='' href={`/platforms`}>
												Platforms
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/languages`}>
												Languages
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/frameworks`}>
												Frameworks
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/tools`}>
												Tools
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/topics`}>
												Topics
											</FooterLink>
										</FooterItem>
									</FooterList>
								</Col>
								<Col span='6' lg='3' className='pb-4'>
									<FooterTitle spacing='mb-4'>Resources</FooterTitle>
									<FooterList>
										<FooterItem>
											<FooterLink size='' href={`${process.env.NEXT_PUBLIC_BLOG_DOMAIN}/news`}>
												News
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`${process.env.NEXT_PUBLIC_BLOG_DOMAIN}/perspectives`}>
												Perspectives
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`${process.env.NEXT_PUBLIC_BLOG_DOMAIN}/engineering`}>
												Engineering
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`${process.env.NEXT_PUBLIC_BLOG_DOMAIN}/case-studies`}>
												Case Studies
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`${process.env.NEXT_PUBLIC_BLOG_DOMAIN}/changelog`}>
												Changelog
											</FooterLink>
										</FooterItem>
									</FooterList>
								</Col>
								<Col span='6' lg='3' className='pb-4'>
									<FooterTitle spacing='mb-4'>Other</FooterTitle>
									<FooterList>
										<FooterItem>
											<FooterLink size='' href={`/licenses`}>
												Licenses
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/terms`}>
												Terms
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/privacy`}>
												Privacy
											</FooterLink>
										</FooterItem>
										<FooterItem>
											<FooterLink size='' href={`/support`}>
												Support
											</FooterLink>
										</FooterItem>
									</FooterList>
								</Col>
							</Grid>
						</Col>
					</Grid>
				</Container>
			</FooterBody>

			<FooterFooter border='border-t'>
				<Container>
					<Grid gap='gap-8'>
						<Col md='6' display='flex-between md:flex-start' space='space-x-8'>
							<FooterText color='gray-500' fontSize='text-sm'>
								{new Date().getFullYear()} © Playbooks, Inc
							</FooterText>
							<IFrame title='Betterstack' src={betterStackUrl} width='200' height='30' />
						</Col>
						<Col md='6' className='lg:flex-end'>
							<AppThemeToggle />
						</Col>
					</Grid>
				</Container>
			</FooterFooter>
		</Footer>
	);
};

export { AppFooter };
